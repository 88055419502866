type ILoggerMessage = Error | string;
const log = (
  message: ILoggerMessage,
  optionalParams?: ILoggerMessage[],
  level: "info" | "warn" | "error" = "info",
) => {
  const isDev = process.env.NODE_ENV !== "production";

  if (isDev) {
    if (level === "info") {
      // eslint-disable-next-line no-console
      console.info(message, optionalParams);
    }
    if (level === "warn") {
      // eslint-disable-next-line no-console
      console.warn(message, optionalParams);
    }
    if (level === "error") {
      // eslint-disable-next-line no-console
      console.error(message, optionalParams);
    }
  }
};

const info = (message: ILoggerMessage, ...optionalParams: ILoggerMessage[]) => {
  log(message, optionalParams, "info");
};

const warn = (message: ILoggerMessage, ...optionalParams: ILoggerMessage[]) => {
  log(message, optionalParams, "warn");
};

const error = (
  message: ILoggerMessage,
  ...optionalParams: ILoggerMessage[]
) => {
  log(message, optionalParams, "error");
};

/**
 * Debugging utility for dev and testing environments.
 */
export const logger = {
  log,
  info,
  warn,
  error,
};
