import React from "react";
import { useFela } from "react-fela";
import { IWithCustomStyle } from "@/hooks/useTheme/types";
import { useTheme } from "@/hooks/useTheme";

interface IText {
  fontSizePx?: number;
}

export const TextLabel: React.FC<IText & IWithCustomStyle> = ({
  customStyle = {},
  children,
  ...rest
}) => {
  const { css } = useFela();
  const { colors, bp, fonts } = useTheme();

  return (
    <div
      className={css({
        nested: {
          "> p": {
            fontFamily: fonts.PRIMARY_FONT,
            fontSize: 18,
            lineHeight: 1.3,
            textAlign: "center",
            margin: 0,
            marginBottom: 24,
            color: colors.GRAY,
            [bp.FROM_DESKTOP]: {
              fontSize: 18,
              lineHeight: 1.38,
            },
            ...customStyle,
          },
        },
      })}
    >
      <p className={css(customStyle)} {...rest}>
        {children}
      </p>
    </div>
  );
};
