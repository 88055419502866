import React from "react";
import useTranslation from "next-translate/useTranslation";
import { useFela } from "react-fela";
import { useTheme } from "@/hooks/useTheme";
import { IWithCustomStyle } from "@/hooks/useTheme/types";
import { Modal } from "../Modal";
import { Heading } from "../Heading";
import { StepTextCard } from "./StepTextCard";

interface IWelcomeAboardModal {
  onClose: () => void;
  isFigmaBackup?: boolean;
}

type IProps = IWelcomeAboardModal & IWithCustomStyle;

export const WelcomeAboardModal: React.FC<IProps> = ({
  onClose,
  isFigmaBackup,
}) => {
  const { css } = useFela();
  const { bp, colors } = useTheme();
  const { t } = useTranslation("welcomeAboardModal");

  return (
    <Modal
      id="welcome-modal"
      onClose={onClose}
      customStyle={{
        minHeight: 320,
        maxHeight: "95vh",
        [bp.FROM_LARGE_DESKTOP]: {
          width: "85%",
        },
      }}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        })}
      >
        <img
          src="/svgs/check-icon-bubbled.svg"
          className={css({
            width: 64,
            marginBottom: 24,
          })}
          alt="check icon in bubble"
        />
        <Heading
          as="h2"
          customStyle={{
            margin: "16px auto",
            color: colors.CHARCOAL,
            [bp.FROM_DESKTOP]: { marginBottom: 16 },
          }}
        >
          {t(`title`)}
        </Heading>

        <Heading
          as="h4"
          customStyle={{
            marginBottom: 16,
            fontWeight: 400,
            letterSpacing: 0.5,
            textAlign: "center",
          }}
        >
          {isFigmaBackup ? t("figmaBackupSubtitle") : t("subtitle")}
        </Heading>

        {!isFigmaBackup && (
          <>
            <p
              className={css({
                fontSize: 18,
                fontWeight: 700,
                letterSpacing: 0.5,
                color: colors.CHARCOAL,
              })}
            >
              {t(`text`)}
            </p>
            <div
              className={css({
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginTop: 32,
                [bp.FROM_TABLET]: {
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                },
              })}
            >
              <StepTextCard
                id={0}
                step={t("checkEmail")}
                stepDescription={t("checkEmailDescription")}
                image={{
                  src: "/svgs/verify-account.svg",
                  alt: "verify account",
                }}
              />
              <StepTextCard
                id={1}
                step={t("setupAccount")}
                stepDescription={t("setupAccountDescription")}
                image={{
                  src: "/svgs/my-account-page.svg",
                  alt: "my account page",
                }}
              />
              <StepTextCard
                id={2}
                step={t("convertFiles")}
                stepDescription={t("convertFilesDescription")}
                image={{
                  src: "/svgs/convert-files-screen.svg",
                  alt: "convert files screen",
                }}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
