import React from "react";

import { useTheme } from "@/hooks/useTheme";
import { Tooltip } from "@/components/Tooltip";

type IPricingTooltip = {
  text: string;
};

export const PricingTooltip: React.FC<IPricingTooltip> = ({
  text,
  children,
}) => {
  const { colors } = useTheme();

  return (
    <Tooltip
      text={text}
      textStyles={{
        textDecoration: "underline",
        textDecorationStyle: "dashed",
        textDecorationColor: colors.DARK_GREY,
        textUnderlineOffset: 3,
        textDecorationThickness: 1,
        position: "relative",
      }}
      tooltipStyles={{
        top: 30,
        left: "50%",
        transform: "translateX(-50%)",
        padding: 10,
        backgroundColor: colors.GRADIENT_PURPLE,
        borderRadius: 6,
        zIndex: 1,
        width: "max-content",
        maxWidth: 260,
      }}
      tooltipArrowStyles={{
        width: 16,
        height: 16,
        backgroundColor: colors.GRADIENT_PURPLE,
        borderRadius: 3,
        transform: "rotate(45deg)",
        left: "calc(50% - 8px)",
        top: -6,
      }}
      tooltipTextStyles={{
        fontSize: 12,
        letterSpacing: 0.25,
        color: colors.WHITE,
      }}
    >
      {children}
    </Tooltip>
  );
};
