import React from "react";
import useTranslation from "next-translate/useTranslation";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { IWithCustomStyle } from "@/hooks/useTheme/types";
import { TextLabel } from "@/components/TextLabel";
import { Button } from "@/components/Button";
import { useAuth } from "@/hooks/useAuth";
import { Modal } from "../Modal";
import { Heading } from "../Heading";

interface ISuccessfulPaymentModal {
  onClose: () => void;
  isFigmaBackup?: boolean;
}

type IProps = ISuccessfulPaymentModal & IWithCustomStyle;

export const SuccessfulPaymentModal: React.FC<IProps> = ({
  onClose,
  isFigmaBackup,
}) => {
  const { login } = useAuth();
  const { css } = useFela();
  const { bp, colors } = useTheme();
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <Modal
      id="success-payment-modal"
      onClose={onClose}
      customStyle={{
        minHeight: 400,
        [bp.FROM_LARGE_DESKTOP]: {
          width: "85%",
        },
      }}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        })}
      >
        {isFigmaBackup ? (
          <img
            src="/svgs/check-icon-bubbled.svg"
            className={css({
              width: 64,
              marginBottom: 40,
            })}
            alt="check icon in bubble"
          />
        ) : (
          <img
            src="/svgs/magician-approved.svg"
            className={css({
              width: 100,
              marginBottom: 21,
              [bp.FROM_TABLET]: { width: 150 },
            })}
            alt="magician"
          />
        )}
        <Heading
          as="h2"
          customStyle={{
            color: colors.CHARCOAL,
            textAlign: "center",
            [bp.FROM_DESKTOP]: { marginBottom: 16 },
          }}
        >
          {" "}
          {t(`successfulPaymentModal:title`)}
        </Heading>
        <Heading
          as="h3"
          customStyle={{
            marginBottom: 16,
            fontWeight: 400,
            letterSpacing: 0.5,
            textAlign: "center",
          }}
        >
          {isFigmaBackup
            ? t(`successfulPaymentModal:subtitleEmailOnboarding`)
            : t(`successfulPaymentModal:subtitle`)}
        </Heading>

        {user ? (
          <Button
            customStyle={{
              maxWidth: "fit-content",
              padding: "10px 25px",
              fontWeight: 500,
            }}
            onClick={onClose}
          >
            {t(`common:ok`)}
          </Button>
        ) : (
          <>
            {!isFigmaBackup && (
              <TextLabel customStyle={{ textAlign: "center", width: 250 }}>
                {t(`successfulPaymentModal:logIntoAccount`)}
              </TextLabel>
            )}
            <Button
              customStyle={{
                maxWidth: "fit-content",
                padding: "10px 25px",
                fontWeight: 500,
              }}
              onClick={() => login(true)}
            >
              {t(`successfulPaymentModal:logInNowBtn`)}
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};
