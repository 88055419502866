import React from "react";
import NextHead from "next/head";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

import { getUrlWithLocale, IUrlArgs } from "./utils";

interface IProps {
  pageNamespace: string;
  ogImage: string;
}

export const Head: React.FC<IProps> = ({ pageNamespace, ogImage }) => {
  const { t } = useTranslation();
  const { locale, locales, defaultLocale, pathname } = useRouter();

  const pageTitle = t(`${pageNamespace}:meta.title`);
  const pageDescription = t(
    `${pageNamespace}:meta.description`,
    {},
    { fallback: "common:meta.title" },
  );

  // TODO: move to dotenv?
  const pageHostname = "https://magicul.io";

  const urlArgs: IUrlArgs = {
    base: pageHostname,
    path: pathname,
    locale: locale!,
    defaultLocale: defaultLocale!,
    locales: locales!,
  };

  const pageUrl = getUrlWithLocale(urlArgs);

  return (
    <NextHead>
      {/* General */}
      <title>{pageTitle}</title>
      <meta name="author" content="Magicul" />
      <meta name="publisher" content="Magicul" />
      <meta name="copyright" content="Magicul" />
      <meta name="description" content={pageDescription} />
      <meta
        name="keywords"
        content={t(
          `${pageNamespace}:meta.keywords`,
          {},
          { fallback: "common:meta.title" },
        )}
      />
      <meta name="page-type" content="Software" />

      {/* Links */}
      {locales!
        .filter(locale => locale !== defaultLocale)
        .map(locale => (
          <link
            key={locale}
            rel="alternate"
            hrefLang={locale}
            href={getUrlWithLocale({ ...urlArgs, locale })}
          />
        ))}
      <link
        rel="alternate"
        hrefLang="x-default"
        href={getUrlWithLocale({ ...urlArgs, locale: defaultLocale! })}
      />
      <link
        rel="canonical"
        href={getUrlWithLocale({ ...urlArgs, locale: locale! })}
      />

      {/* OG */}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content={`${pageHostname}${ogImage}`} />
      <meta property="og:image:alt" content={pageTitle} />
    </NextHead>
  );
};
