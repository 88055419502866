import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { useTheme } from "@/hooks/useTheme";
import { IIconType } from "@/icons";
import { Icon, StyledIconWrapper } from "@/components/Icon";

interface IProps {
  supportedFormats?: IIconType[];
  supportedFormatsTitle?: string;
}

const defaultFormats: IIconType[] = [
  "PHOTOSHOP",
  "PDF",
  "ADOBEXD",
  "AI",
  "FIGMA",
  "SKETCH",
];

export const SupportedFormats: React.FC<IProps> = ({
  supportedFormats = defaultFormats,
  supportedFormatsTitle,
}) => {
  const { css } = useFela();
  const { colors } = useTheme();
  const { t } = useTranslation("section/pricing");

  const shortList = supportedFormats.length < 5;

  return (
    <div
      className={css({
        marginBottom: 24,
      })}
    >
      <p
        className={css({
          fontSize: 14,
          marginBottom: 15,
          letterSpacing: 0.5,
          fontWeight: "bold",
          color: colors.DARK_GREY,
        })}
      >
        {supportedFormatsTitle || t("supportedFormats")}
      </p>

      <div
        className={css({
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: shortList ? "flex-start" : "space-between",

          nested: {
            "> div": {
              marginRight: shortList ? 25 : 0,
            },
          },
        })}
      >
        {supportedFormats.map(supportedFormat => (
          <StyledIconWrapper height={32} key={supportedFormat}>
            <Icon iconType={supportedFormat} />
          </StyledIconWrapper>
        ))}
      </div>
    </div>
  );
};
