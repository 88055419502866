import React from "react";

import { WelcomeAboardModal } from "@/components/WelcomeAboardModal";
import { SuccessfulPaymentModal } from "@/components/SuccessfulPaymentModal";

interface IProps {
  newUser: boolean;
  onClose: () => void;
  isFigmaBackup?: boolean;
}

export const PaymentSuccessfulPopup: React.FC<IProps> = props => {
  const { newUser, ...rest } = props;
  return newUser ? (
    <WelcomeAboardModal {...rest} />
  ) : (
    <SuccessfulPaymentModal {...rest} />
  );
};
