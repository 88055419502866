export type IPricingPlan =
  | "ONE_TIME_PLAN"
  | "ONE_TIME_PLAN_LARGE"
  | "SUBSCRIPTION_ENTERPRISE"
  | "SUBSCRIPTION_LIFETIME"
  | "SUBSCRIPTION_UNLIMITED_ANNUAL"
  | "CREDITS_1"
  | "CREDITS_2"
  | "CREDITS_3"
  | "CREDITS_4"
  | "BACKUP_BASIC"
  | "BACKUP_PRO"
  | "BACKUP_ENTERPRISE"
  | "BACKUP_LIFETIME"
  | "BACKUP_UNLIMITED_ANNUAL";

export const PRODUCTS: Record<IPricingPlan, string> = {
  ONE_TIME_PLAN: process.env.NEXT_PUBLIC_XD2SKETCH_CHARGEBEE_ONE_TIME_PLAN,
  ONE_TIME_PLAN_LARGE:
    process.env.NEXT_PUBLIC_XD2SKETCH_CHARGEBEE_ONE_TIME_PLAN_LARGE,
  SUBSCRIPTION_ENTERPRISE:
    process.env.NEXT_PUBLIC_XD2SKETCH_CHARGEBEE_SUBSCRIPTION_ENTERPRISE,
  SUBSCRIPTION_LIFETIME:
    process.env.NEXT_PUBLIC_XD2SKETCH_CHARGEBEE_SUBSCRIPTION_LIFETIME,
  SUBSCRIPTION_UNLIMITED_ANNUAL:
    process.env.NEXT_PUBLIC_XD2SKETCH_CHARGEBEE_SUBSCRIPTION_UNLIMITED_ANNUAL,
  CREDITS_1: process.env.NEXT_PUBLIC_XD2SKETCH_CHARGEBEE_CREDITS_1,
  CREDITS_2: process.env.NEXT_PUBLIC_XD2SKETCH_CHARGEBEE_CREDITS_2,
  CREDITS_3: process.env.NEXT_PUBLIC_XD2SKETCH_CHARGEBEE_CREDITS_3,
  CREDITS_4: process.env.NEXT_PUBLIC_XD2SKETCH_CHARGEBEE_CREDITS_4,

  BACKUP_BASIC: process.env.NEXT_PUBLIC_XD2SKETCH_CHARGEBEE_BACKUP_BASIC,
  BACKUP_PRO: process.env.NEXT_PUBLIC_XD2SKETCH_CHARGEBEE_BACKUP_PRO,
  BACKUP_ENTERPRISE:
    process.env.NEXT_PUBLIC_XD2SKETCH_CHARGEBEE_BACKUP_ENTERPRISE,
  BACKUP_LIFETIME: process.env.NEXT_PUBLIC_XD2SKETCH_CHARGEBEE_BACKUP_LIFETIME,
  BACKUP_UNLIMITED_ANNUAL:
    process.env.NEXT_PUBLIC_XD2SKETCH_CHARGEBEE_BACKUP_UNLIMITED_ANNUAL,
};
