import * as Sentry from "@sentry/node";
import Axios from "axios";

import { BACKEND_ENDPOINTS } from "@/constants/backend";
import { IConversionType } from "@/containers/Converter/types";

import { storePurchase } from "./purchaseHistoryService";

type ICheckoutParams = {
  fileName?: string;
  planId: string;
  planQuantity?: number;
  conversionType: IConversionType | string;
  numberOfArtboards?: number;
  email?: string;
};

declare const Chargebee: {
  getInstance: () => {
    openCheckout: (options: {
      hostedPage: () => Promise<any>;
      success: (hostedPageId: string) => Promise<any>;
      error: (error: string) => void;
      close?: () => void;
    }) => any;
    closeAll: () => void;
  };
};

interface IHandleCheckoutBody {
  [key: string]: string | number | undefined;
}

export interface IHandleCheckoutResponse {
  hostedPageId: string;
  isNewUser: boolean;
}

const isDev = process.env.NODE_ENV !== "production";

const formBody = (
  planId: string,
  planQuantity: number | undefined,
  fileName: string | undefined,
  conversionType: IConversionType | string,
  numberOfArtboards: number | undefined,
  email: string | undefined,
) => {
  const body: IHandleCheckoutBody = {
    planId,
    planQuantity,
    conversionType,
    numberOfArtboards,
    email,
  };

  if (fileName) {
    body.fileName = fileName;
  }

  if (numberOfArtboards) {
    body.numberOfArtboards = numberOfArtboards.toString();
  }

  return JSON.stringify(body);
};

export const handleCheckout = ({
  fileName,
  planId,
  planQuantity,
  conversionType,
  numberOfArtboards,
  email,
}: ICheckoutParams): Promise<IHandleCheckoutResponse> => {
  const cbInstance = Chargebee.getInstance();

  return new Promise((resolve, reject) => {
    let storedHostedPageId = "";
    cbInstance.openCheckout({
      hostedPage: async () => {
        const resp = await fetch(BACKEND_ENDPOINTS.payment, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: formBody(
            planId,
            planQuantity,
            fileName,
            conversionType,
            numberOfArtboards,
            email,
          ),
        });
        const jsonResp = await resp.json();
        storedHostedPageId = jsonResp?.hosted_page?.id;
        return jsonResp.hosted_page;
      },
      success: async (hostedPageId: string) => {
        try {
          const result = await Axios.get(
            `${BACKEND_ENDPOINTS.paymentVerify}?hostedPageId=${hostedPageId}`,
          );
          storePurchase({ fileName, planId, conversionType });
          resolve({
            hostedPageId,
            isNewUser: result.data.isNewUser,
          });
        } catch (error) {
          Sentry.withScope(scope => {
            scope.setExtras({ fileName });
            scope.setTag("where", "Services: handleCheckout -> verify payment");
            Sentry.captureException(error);
          });
          reject(error);
        } finally {
          cbInstance.closeAll();
        }
      },
      error: (error: string) => {
        if (error?.startsWith("Already another checkout is in progress")) {
          // this happens when user opens checkout, closes it before it finishes
          // loading and then tries to open checkout again. The success callback
          // will still be called if user completes the checkout
          return;
        }

        Sentry.withScope(scope => {
          scope.setExtras({ fileName });
          scope.setTag("where", "Services: handleCheckout");
          Sentry.captureException(error);
        });
        if (isDev) {
          // eslint-disable-next-line no-console
          console.error(`Failed to complete the payment: ${error}`);
        }
        reject(error);
      },
      close: async () => {
        try {
          cbInstance.closeAll();
          const result = await Axios.get(
            `${BACKEND_ENDPOINTS.paymentVerify}?hostedPageId=${storedHostedPageId}`,
          );
          storePurchase({ fileName, planId, conversionType });
          resolve({
            hostedPageId: storedHostedPageId,
            isNewUser: result.data.isNewUser,
          });
        } catch (error) {
          Sentry.withScope(scope => {
            scope.setExtras({ fileName });
            scope.setTag("where", "Services: handleCheckout -> verify payment");
            Sentry.captureException(error);
          });
          reject(error);
        }
      },
    });
  });
};
