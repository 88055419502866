import React from "react";
import { useFela } from "react-fela";

import { IWithCustomStyle } from "@/hooks/useTheme";

export type IPrice = {
  preffix?: string;
  value: number;
  suffix?: string;
} & IWithCustomStyle;

export const Price: React.FC<IPrice> = ({
  preffix = "$",
  value,
  suffix,
  customStyle = {},
}) => {
  const { css } = useFela();

  return (
    <div className={css(customStyle)}>
      <span
        className={css({
          fontSize: 14,
        })}
      >
        {preffix}
      </span>

      <strong
        className={css({
          fontSize: 24,
          letterSpacing: 1,
        })}
      >
        {value}
      </strong>

      <span
        className={css({
          fontSize: 14,
        })}
      >
        {suffix && <span>/</span>}
        {suffix}
      </span>
    </div>
  );
};
