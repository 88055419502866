import React from "react";
import { useFela } from "react-fela";

import { Card } from "@/components/Card";
import { Button } from "@/components/Button";
import { useTheme, IWithCustomStyle } from "@/hooks/useTheme";

import { IPricingCard } from "./types";
import { Header } from "./Header";
import { SupportedFormats } from "./SupportedFormats";
import { Icon } from "../Icon";
import { PricingTooltip } from "./PricingTooltip";

export const PricingCard: React.FC<IPricingCard & IWithCustomStyle> = ({
  header,
  supportedFormats,
  children,
  cta,
  supportedFormatsTitle,
  customStyle = {},
}) => {
  const { bp, colors } = useTheme();
  const { css } = useFela();

  return (
    <Card
      customStyle={{
        width: "100%",
        maxWidth: "85vw",
        margin: "0 auto",
        [bp.FROM_DESKTOP]: { minHeight: 560, maxWidth: 375 },
        [bp.FROM_LARGE_DESKTOP]: { minHeight: 520 },
        ...customStyle,
      }}
    >
      {header ? <Header {...header} /> : null}

      <div className={css({ flex: 1, marginBottom: 40 })}>{children}</div>

      <SupportedFormats
        supportedFormats={supportedFormats}
        supportedFormatsTitle={supportedFormatsTitle}
      />

      <Button title={cta.title.toUpperCase()} onClick={cta.onClick}>
        {cta.title}
      </Button>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 14,
        }}
      >
        <Icon iconType="SHIELD" />
        <span style={{ fontSize: 15, color: colors.DARK_GREY, marginLeft: 12 }}>
          14-day{" "}
          <PricingTooltip text="Not happy with the conversion results? We got you covered! Simply send us a message and we fill refund your money. Our general refund policy applies.">
            money back guarantee*
          </PricingTooltip>
        </span>
      </div>
    </Card>
  );
};

export type { IPricingCard };
export { SupportedFormats };
export * from "./ListElement";
export * from "./PricingTooltip";
export * from "./RadioElement";
export * from "./Price";
export * from "./BulletedList";
