import { useTheme } from "@/hooks/useTheme";
import React from "react";
import { useFela } from "react-fela";

export const BulletedList: React.FC = ({ children }) => {
  const { css } = useFela();
  const { colors } = useTheme();

  return (
    <ul
      className={css({
        margin: "24px 0 0",
        paddingLeft: 0,
        listStyle: "none",
      })}
    >
      {React.Children.map(children, item => (
        <li
          className={css({
            position: "relative",
            paddingLeft: 22,
            fontSize: 14,
            lineHeight: "24px",
            color: colors.DARK_GREY,
            fontWeight: 600,
            nested: {
              "&:not(:last-child)": {
                marginBottom: 10,
              },
              "&::before": {
                content: "''",
                width: 8,
                height: 8,
                position: "absolute",
                left: 0,
                top: 12,
                transform: "translateY(-50%)",
                backgroundColor: colors.LIGHT_GRAY,
                borderRadius: "50%",
                marginRight: 15,
              },
            },
          })}
        >
          {item}
        </li>
      ))}
    </ul>
  );
};
