import React from "react";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { IWithCustomStyle } from "@/hooks/useTheme/types";

import { Heading } from "../../Heading";
import { IStepTextCard } from "../types";

type IProps = IStepTextCard & IWithCustomStyle;

export const StepTextCard: React.FC<IProps> = ({
  id,
  image: { src, alt },
  step,
  stepDescription,
  customStyle = {},
}) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();

  return (
    <div
      className={css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        marginBottom: 44,
        [bp.FROM_TABLET]: {
          flexBasis: "33%",
          padding: "0 10px",
        },
        ...customStyle,
      })}
    >
      <div
        className={css({
          width: 48,
          height: 48,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto 24px",
          background: colors.GRADIENT_PURPLE,
          borderRadius: "50%",
          fontSize: 24,
          lineHeight: 32,
          color: colors.WHITE,
          flexShrink: 0,
        })}
      >
        {id + 1}
      </div>
      <img
        src={src}
        alt={alt}
        className={css({
          width: 318,
          height: 259,
          boxShadow: `16.5195px 16.5195px 66.0779px ${colors.CETACEAN_BLUE}`,
          borderRadius: 7,
          flexShrink: 0,
        })}
      />
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flexShrink: 0,
          marginTop: 32,
          textAlign: "center",
        })}
      >
        <Heading as="h3" customStyle={{ marginBottom: 16 }}>
          {step}
        </Heading>
        <p
          className={css({
            maxWidth: 318,
            fontSize: 14,
            lineHeight: "24px",
            color: colors.GRAY,
          })}
        >
          {stepDescription}
        </p>
      </div>
    </div>
  );
};
