import React from "react";
import { useFela, CssFelaStyle } from "react-fela";

type IProps = {
  text: string;
  wrapperStyles?: CssFelaStyle<unknown, unknown>;
  textStyles?: CssFelaStyle<unknown, unknown>;
  tooltipStyles?: CssFelaStyle<unknown, unknown>;
  tooltipArrowStyles?: CssFelaStyle<unknown, unknown>;
  tooltipTextStyles?: CssFelaStyle<unknown, unknown>;
};

export const Tooltip: React.FC<IProps> = ({
  text,
  children,
  textStyles = {},
  tooltipStyles = {},
  tooltipArrowStyles = {},
  tooltipTextStyles = {},
}) => {
  const { css } = useFela();

  return (
    <span
      className={css({
        position: "relative",
        cursor: "pointer",
        ...textStyles,

        nested: {
          ":hover": {
            nested: {
              "> span": {
                opacity: 1,
              },
            },
          },
        },
      })}
    >
      {children}

      <span
        className={css({
          display: "block",
          position: "absolute",
          opacity: 0,
          transition: "opacity .2s ease",
          pointerEvents: "none",

          ...tooltipStyles,

          nested: {
            "::before": {
              content: '""',
              position: "absolute",
              ...tooltipArrowStyles,
            },
          },
        })}
      >
        <span
          className={css({
            position: "relative",
            zIndex: 1,
            ...tooltipTextStyles,
          })}
        >
          {text}
        </span>
      </span>
    </span>
  );
};
