import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { useTheme } from "@/hooks/useTheme";

import { Price, IPrice } from "../Price";

export interface IPricingRadioElementProps {
  price: IPrice;
  saving?: number;
  selected: boolean;
  onSelect: () => void;
}

export const PricingRadioElement: React.FC<IPricingRadioElementProps> = ({
  price,
  saving,
  children,
  selected,
  onSelect,
}) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation("section/pricing");

  return (
    <div
      role="radio"
      aria-checked={selected}
      onClick={onSelect}
      className={css({
        position: "relative",
        display: "flex",
        alignItems: "center",
        minHeight: 90,
        color: colors.DARK_GREY,
        cursor: "pointer",

        nested: {
          ":not(:first-of-type)": {
            borderTop: `1px solid ${colors.STARDUST}`,
          },
        },
      })}
    >
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          alignSelf: "flex-start",
          marginTop: 15,
        })}
      >
        <div
          className={css({
            flex: "0 0 auto",
            width: 20,
            height: 20,
            marginRight: 20,
            borderRadius: "50%",
            transition: "background-color .2s ease",
            backgroundColor: selected ? colors.ORANGE : colors.STARDUST,
            border: `5px solid ${colors.WHITE}`,
            boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.17)",
          })}
        />

        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            flex: "0 0 auto",
          })}
        >
          <Price {...price} />

          {saving && (
            <span
              className={css({
                fontSize: 12,
                borderRadius: 6,
                flexGrow: 0,
                alignSelf: "flex-start",
                color: colors.WHITE,
                padding: "2px 8px",
                marginTop: "6px",
                backgroundColor: "#FF8C93",
              })}
            >
              {t("plans.credits.savingLabel", { percent: saving })}
            </span>
          )}
        </div>
      </div>

      <div
        className={css({
          fontSize: 14,
          maxWidth: "none",
          width: "100%",
          lineHeight: "24px",
          letterSpacing: 0.5,
          marginLeft: 30,
          color: colors.DARK_GREY,
          [bp.ONLY_MOBILE]: {
            maxWidth: 180,
          },
          [bp.ONLY_SMALL_MOBILE]: {
            maxWidth: 135,
          },
          [bp.FROM_DESKTOP]: {
            maxWidth: 192,
          },
        })}
      >
        {React.Children.map(children, (desc, i) => (
          <React.Fragment key={`description-${price}-${i}`}>
            {desc}
            <br
              key={`separator-${i}`}
              className={css({
                nested: {
                  ":not(:last-of-type)": {
                    content: "''",
                    marginBottom: 10,
                    display: "block",
                    lineHeight: "20px",
                  },
                },
              })}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
