import { IConversionType } from "../containers/Converter/types";
import { LOCAL_STORAGE_KEYS } from "../constants/localStorage";

type IPurchaseDetails = {
  fileName?: string;
  planId: string;
  conversionType: IConversionType | string;
  email?: string;
  paymentAmount?: string;
};

export const getPurchaseHistory = () => {
  const purchaseHistoryRaw = localStorage.getItem(
    LOCAL_STORAGE_KEYS.purchaseHistory,
  );
  const purchaseHistory = purchaseHistoryRaw
    ? JSON.parse(purchaseHistoryRaw)
    : [];
  return purchaseHistory;
};

export const storePurchase = (params: IPurchaseDetails) => {
  const purchaseHistory = getPurchaseHistory();
  purchaseHistory.push(params);
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.purchaseHistory,
    JSON.stringify(purchaseHistory),
  );
};

export const getLastPurchase = () => {
  return getPurchaseHistory().pop() || {};
};
