export const PRICING = {
  SMALL_FILE_ARTBOARD_LIMIT: 50,

  PAY_AS_YOU_GO: {
    price: 94,
    priceLargeFile: 124,
  },

  CREDITS: [
    {
      price: 185,
      saving: 15,
      credits: 3,
    },
    {
      price: 245,
      saving: 30,
      credits: 6,
    },
    {
      price: 345,
      saving: 47,
      credits: 12,
    },
    {
      price: 485,
      saving: 64,
      credits: 25,
    },
  ],

  SUBSCRIPTION: {
    price: 2895,
  },
};

export const PRICING_BACKUP_BASIC_PLAN: Record<number, number> = {
  50: 49,
  100: 79,
  200: 139,
  400: 229,
  800: 349,
  1000: 0,
};

export const PRICING_BACKUP_PRO_PLAN: Record<number, number> = {
  50: 74,
  100: 99,
  200: 175,
  400: 259,
  800: 389,
  1000: 0,
};

export const PRICING_BACKUP_ENTERPRISE_PLAN: Record<number, number> = {
  50: 119,
  100: 179,
  200: 259,
  400: 479,
  800: 699,
  1000: 0,
};

export const PRICING_BACKUP_SLIDER_VALUES = [50, 100, 200, 400, 800, 1000];

export const CALENDLY_LINK =
  "https://calendly.com/kevin-goedecke/figma-backup-demo";

export const CALENDLY_LINK_PRODUCT_DEMO =
  "https://calendly.com/kevin-goedecke/15-min-product-demo";
