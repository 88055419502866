import { IPricingPlan } from "@/constants/products";

import { IPaymentType } from "../../types";

export const getCheckoutPricingPlan = (
  paymentType: IPaymentType,
): IPricingPlan => {
  if (paymentType === "ONE_TIME_PAYMENT") {
    return "ONE_TIME_PLAN";
  }

  if (paymentType === "ONE_TIME_PAYMENT_LARGE") {
    return "ONE_TIME_PLAN_LARGE";
  }

  if (paymentType) {
    return paymentType as IPricingPlan;
  }

  throw Error("Invalid checkout plan");
};
