import React from "react";
import { useFela } from "react-fela";
import useTranslation from "next-translate/useTranslation";

import { useTheme } from "@/hooks/useTheme";
import { StyledIconWrapper, Icon } from "@/components/Icon";

import { Price, IPrice } from "../Price";

type IProps = {
  price: IPrice;
  disabled?: boolean;
  selected?: boolean;
  artboardsCount?: number;
};

/**
 * Informational element.
 * No interactions, used in PAYG
 *
 * When used during checkout:
 * - use `disabled` to indicate that this item is inactive
 * - use `selected` to indicate that this item is active
 * - use `artboardsCount` along with `selected` to show tooltip
 */
export const PricingListElement: React.FC<IProps> = ({
  price,
  children,
  disabled,
  selected,
  artboardsCount = 0,
}) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();
  const { t } = useTranslation("section/pricing");

  return (
    <>
      <div
        className={css({
          position: "relative",
          display: "flex",
          alignItems: "center",
          minHeight: 70,
          color: colors.DARK_GREY,

          opacity: disabled ? 0.3 : 1,
          pointerEvents: disabled ? "none" : "initial",

          nested: {
            ":not(:first-of-type)": {
              borderTop: `1px solid ${colors.STARDUST}`,
            },
          },
        })}
      >
        <div
          className={css({
            display: "flex",
            alignItems: "center",
            alignSelf: "flex-start",
            margin: "15px 25px 0 0",
          })}
        >
          <Price {...price} />
        </div>

        <div className={css({ fontSize: 14 })}>
          {React.Children.map(children, (desc, i) => (
            <React.Fragment key={`description-${price}-${i}`}>
              {desc}
              <br
                key={`separator-${i}`}
                className={css({
                  nested: {
                    ":not(:last-of-type)": {
                      content: "''",
                      marginBottom: 10,
                      display: "block",
                      lineHeight: "20px",
                    },
                  },
                })}
              />
            </React.Fragment>
          ))}
        </div>
      </div>

      {selected ? (
        <div
          className={css({
            display: "flex",
            justifyContent: "flex-end",
            margin: "-10px 0 20px",
            fontFamily: "'Nunito', sans-serif",
          })}
        >
          <StyledIconWrapper
            customStyle={{
              marginRight: 5,
              [bp.FROM_TABLET]: {
                marginRight: 20,
              },
            }}
          >
            <Icon iconType="TIP-ARROW-ICON-BIG" />
          </StyledIconWrapper>
          <p
            className={css({
              borderRadius: 8,
              backgroundColor: "#3ec6dd",
              fontSize: 12,
              color: colors.WHITE,
              padding: 12,
              maxWidth: "initial",
              width: "77%",
              [bp.ONLY_SMALL_MOBILE]: {
                maxWidth: 135,
              },
              [bp.FROM_DESKTOP]: {
                maxWidth: 192,
              },
            })}
          >
            {t("tooltips.selectedPAYGOption", {
              count: artboardsCount === 0 ? artboardsCount + 1 : artboardsCount,
            })}
          </p>
        </div>
      ) : null}
    </>
  );
};
