import React from "react";
import { useFela } from "react-fela";
import Trans from "next-translate/Trans";

import { useTheme, IWithCustomStyle } from "@/hooks/useTheme";

type IProps = IWithCustomStyle & JSX.IntrinsicElements["p"];

export const PricesCurrencyText: React.FC<IProps> = ({ customStyle = {} }) => {
  const { css } = useFela();
  const { colors } = useTheme();

  return (
    <p
      className={css({
        marginTop: 40,
        color: colors.LIGHT_GRAY,
        fontWeight: 600,
        letterSpacing: 0.5,
        ...customStyle,
      })}
    >
      <Trans
        i18nKey="common:pricesInUsd"
        components={[
          <span
            key="0"
            className={css({
              color: colors.BLACK,
              textDecoration: "underline",
            })}
          />,
        ]}
      />
    </p>
  );
};
