export interface IUrlArgs {
  base: string;
  path: string;
  locale: string;
  defaultLocale: string;
  locales: string[];
}

export const getUrlWithLocale = ({
  base,
  path,
  locale,
  defaultLocale,
  locales,
}: IUrlArgs): string => {
  if (!base || !path || !locale || !defaultLocale) {
    throw new Error("Can't generate metadata");
  }

  let pageUrl = base;

  // add locale
  if (locale !== defaultLocale) {
    pageUrl += `/${locale}`;
  }

  // check if path already contains locale
  let pathToAppend = path;
  if (locales.includes(path.split("/")[1])) {
    pathToAppend = path.substring(3, path.length);
  }

  // remove trailing slash if any
  if (pathToAppend[pathToAppend.length - 1] === "/") {
    pathToAppend = pathToAppend.substring(0, pathToAppend.length - 1);
  }

  // append pathname
  pageUrl += pathToAppend;

  return pageUrl;
};
