import React from "react";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { IWithCustomStyle } from "@/hooks/useTheme/types";

export const Guarantee: React.FC<IWithCustomStyle> = ({
  customStyle = {},
  ...rest
}) => {
  const { css } = useFela();
  const { bp, colors } = useTheme();

  return (
    <div
      className={css(
        {
          height: 64,
          margin: "6px 8px",
          display: "flex",
          borderRadius: 8,
          padding: "16px 26px",
          alignItems: "center",
          justifyContent: "center",
          color: "#00193e",
          fontWeight: "bold",
          textTransform: "uppercase",
          backgroundColor: colors.WHITE,
          boxShadow: `20px 20px 80px 0 ${colors.CETACEAN_BLUE}`,
          [bp.FROM_DESKTOP]: {
            margin: 0,
          },
        },
        customStyle,
      )}
      {...rest}
    />
  );
};
