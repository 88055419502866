import React from "react";
import { useFela } from "react-fela";

import { useTheme } from "@/hooks/useTheme";
import { Badge } from "@/components/Badge";
import { Icon } from "@/components/Icon";

import { backgroundVariant } from "@/containers/PricingCard/variants";
import { IPricingCardHeader } from "./types";

export const Header: React.FC<IPricingCardHeader> = ({
  title,
  backgroundType,
  subtitle,
}) => {
  const { css } = useFela();
  const { colors, bp } = useTheme();

  const background = backgroundVariant[backgroundType] || colors.WHITE;

  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "flex-start",
        paddingBottom: 10,
        marginBottom: 10,
        [bp.FROM_DESKTOP]: {
          marginLeft: 0,
        },
      })}
    >
      <Badge
        size={64}
        customStyle={{
          background,
          flexShrink: 0,
          transform: "translateY(10px)",
        }}
      >
        <Icon iconType="STAR-1" />
      </Badge>

      <div
        className={css({
          margin: "10px 0 0 25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        })}
      >
        <h3
          className={css({
            margin: 0,
            fontSize: 22,
            letterSpacing: 1,
            color: colors.DARK_GREY,
            [bp.FROM_TABLET]: {
              fontSize: 24,
            },
          })}
        >
          {title}
        </h3>

        <p
          className={css({
            margin: "4px 0 0",
            fontSize: 16,
            fontWeight: "bold",
            letterSpacing: 0.5,
            color: colors.GRAY,
          })}
        >
          {subtitle}
        </p>
      </div>
    </div>
  );
};
