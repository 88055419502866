import React from "react";
import { useFela } from "react-fela";
import { useTheme } from "../../hooks/useTheme";
import { StyledIconWrapper, Icon } from "../../components/Icon";
import { IWithCustomStyle } from "../../hooks/useTheme/types";
import { Guarantee } from "../Converter/PreviewAndPayment/components/Payment/Guarantee";

type IPaymentGuarantee = IWithCustomStyle & JSX.IntrinsicElements["div"];

export const PaymentGuarantee: React.FC<IPaymentGuarantee> = ({
  customStyle = {},
}) => {
  const { css } = useFela();
  const { bp } = useTheme();
  return (
    <div
      className={css(
        {
          margin: "30px auto",
          [bp.FROM_TABLET]: {
            width: "60%",
          },
        },
        customStyle,
      )}
    >
      <Guarantee
        customStyle={{
          height: 115,
          flexDirection: "column",
          [bp.FROM_DESKTOP]: {
            height: 60,
            flexDirection: "row",
          },
        }}
      >
        <div className={css({ display: "flex", alignItems: "center" })}>
          <StyledIconWrapper customStyle={{ marginRight: 20, marginTop: 5 }}>
            <Icon iconType="SHIELD-2" />
          </StyledIconWrapper>
          <strong>Money back guarantee</strong>
        </div>

        <div className={css({ display: "flex", alignItems: "center" })}>
          <StyledIconWrapper
            customStyle={{
              marginRight: 20,
              marginTop: 5,
              [bp.FROM_DESKTOP]: {
                marginLeft: 40,
              },
            }}
          >
            <Icon iconType="LOCK-2" />
          </StyledIconWrapper>
          <strong>100% secure payment</strong>
        </div>
      </Guarantee>
    </div>
  );
};
